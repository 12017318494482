'use client';

import { Disconnect } from '@ahamove/icons/empty';
import * as Sentry from '@sentry/nextjs';
import type Error from 'next/error';
import { useEffect } from 'react';
import { ResultBlock } from '@/components/result-block';
import { Button } from '@/components/ui/button';
import { ROUTES } from '@/config/router';
import { Link } from '@/i18n/routing';

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <html lang="en">
      <body>
        <ResultBlock
          icon={Disconnect}
          title="Opps, something went wrong"
          description="Please try again later."
        >
          {error.digest ? (
            <p className="text-xs font-semibold text-neutral-70 dark:text-neutral-30">
              Error digest: <code>{error.digest}</code>
            </p>
          ) : null}

          <div className="!mt-6 flex flex-row flex-wrap items-center justify-center gap-4">
            <Button
              variant="outline"
              size="sm"
              className="min-w-48 bg-muted"
              onClick={() => reset()}
            >
              Refresh the page
            </Button>
            <Link href={ROUTES.home} passHref>
              <Button variant="outline" size="sm" className="min-w-48">
                Back to Homepage
              </Button>
            </Link>
          </div>
        </ResultBlock>
      </body>
    </html>
  );
}
